
/*=======================================================================================
                                :-large/dasktop Layout-:-
*=============================================================================================*/
@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1470px;
    }
    .slider__wrapper{
        max-width: 1720px;
        margin: 0 auto;
        border-radius: 30px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    /* Navbar */
    .manu .hidden {
        flex: 0 0 78%;
    }

    /* Why Register */
    .why__register__wrapper .no-gutters {
        height: 784px;
        overflow: hidden;
    }

    .why__right {
        padding: 80px 33px;
        width: 100%;
    }

    .list__options {
        overflow: scroll;
        height: 370px;
    }

    /* event details */
    .events__detils__left,
    .event__info_wr,
    .calander__wr{
        padding: 45px;
    }
    .registra__location__wr a {
        margin-bottom: 20px;
    }

}

/*=======================================================================================
                                :-Tab Layout-:-
*=============================================================================================*/
@media only screen and (max-width: 991px) {
    .padding__top__botton {
        padding: 60px 0;
    }
    .margin__top__bottom{
        margin:60px 0;
    }
    .padding__top {
        padding-top: 60px;
    }
    p.learn__contnet2 {
        padding-right: 0;
    }
    .padding__bottom {
        padding-bottom: 60px;
    }

    .margin__top {
        margin-top: 60px;
    }

    .margin__bottom {
        margin-bottom: 60px;
    }

    /* Navbar */
    .mobile__menu {
        display: block;
        height: 27px;
    }

    .manu {
        flex-grow: 0;
    }
    ul.mega{
        width: auto;
    }
    .navbar__inner_container {
        align-items: center;
    }

    .manu ul {
        display: initial;
        padding: 41px 0px;
    }

    .manu ul li {
        padding: 7px 24px;
        position: relative;
    }

    ul.hidden {
        transform: translateX(100%);
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        transition: 0.5s ease;
    }

    ul.vissible {
        transform: translateX(0%);
        position: fixed;
        width: 100%;
        height: 100%;
        background: #fff;
        left: 0;
        top: 0;
        z-index: 9;
        transition: 0.5s ease;
        overflow: scroll;
        display: block;
    }

    .vissible ul.mega {
        transform: translateX(0px);
        display: none;
        left: 7%;
        top: 50px;
        padding: 5px 0;
        transition: all linear 0.3s;
    }

    .mega__visible {
        visibility: visible !important;
        display: block !important;
    }

    ul.vissible li a {
        padding: 0;
    }

    ul.vissible .active {
        color: #564fff;
    }

    .vissible__cross {
        z-index: 13;
        display: block;
        position: fixed;
        top: 4%;
        right: 4%;
        font-size: 18px;
        cursor: pointer;
        border: 1px solid #ffab4a;
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 5px;
        border-radius: 50%;
        color: #ffab4a;
    }

    /* Slider */
    .slider__wrapper {
        padding: 80px 0 111px 0;
    }

    .row__reverce {
        display: flex;
        flex-direction: column-reverse;
    }

    .slider__inner .slick-prev,
    .slider__inner .slick-next {
        top: 112%;
    }

    /* LearnAbout */
    .learn__right {
        padding-top: 50px;
    }

    .learn__about__img .video__btn {
        top: 37%;
        left: 39%;
    }

    .learn__right .section__title__container {
        padding: 0;
    }

    .learn__right .list__content {
        width: 100%;
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }

    .learn__about__wrapper:before {
        display: none;
    }

    .learn__right .up__art {
        position: absolute;
        bottom: 7%;
        right: 0%;
    }

    /* Service */
    .more__service a {
        width: 50%;
    }

    /* Teacher */
    .more__teacher a.teacher__btn {
        width: 30%;
    }

    /* Join Session */
    .joinsession__wrapper:before,
    .joinsession__wrapper:after {
        display: none;
    }

    /* Subscribe */
    .subscriber__wrapper:before,
    .subscriber__wrapper:after {
        display: none;
    }

    .subscriber__wrapper .form-group {
        width: 55%;
    }

    /* Footer */
    .footer__one,
    .footer__title {
        padding-bottom: 15px;
    }

    /* Teacher Page */
    .last__child_remove div:last-child {
        margin-bottom: 0;
    }

    .mar__bottom_30 {
        margin-bottom: 30px;
    }

    /* Contact Page */
    .contact__left {
        margin-bottom: 30px;
    }

    .contact__right,
    .contact__left {
        height: auto;
    }
    /* Event Blog Page */
    .ac__single{
        margin-bottom: 30px;
    }
    .blog__events__wrapper{
        padding: 60px 0 30px 0;
    }
    .cointer__single {
        padding: 12px 0;
    }

    /* Event Details */
    .event__info_wr{
        margin-top: 40px;
    }
    /* Class Details */
    .tab__pannel__row div:last-child{
         margin-bottom: 0px;
    }
    .tab__teacher__clmn{
        margin-bottom: 30px;
    }

}

/*=======================================================================================
                                :-Mobile Layout-:-
*=============================================================================================*/
@media only screen and (max-width: 767.98px) {
    .section__margin__bottom {
        margin-bottom: 40px;
    }

    h1 {
        font-size: 50px;
        line-height: 60px;
    }

    h2 {
        font-size: 35px;
        line-height: 45px;
        font-weight: 700;
    }

    /* Navbar */
    .navbar__inner_container {
        height: 75px;
    }

    .navbar__top {
        height: auto;
        text-align: center;
        padding: 8px 0 5px 0;
    }

    .navbar__top .nav__top__left {
        display: inline-block;
    }

    .navbar__top .top__social {
        justify-content: center;
    }

    ul.hidden {
        width: auto;
    }

    /* Why Register */
    .why__right .list__one__half {
        height: 60px;
        width: 60px;
    }

    .why__right {
        padding: 80px 116px;
        width: 100%;
    }

    .why__img img {
        height: 450px;
    }

    /* Teacher */
    .more__teacher a {
        width: 100%;
        display: block;
    }

    .more__teacher a.teacher__btn {
        width: 56%;
        margin: 10px;
    }

    /* Join Session */
    .joinsession__wrapper {
        padding: 60px 0px;
    }

    .joinsession__wrapper a.session__join {
        width: 50%;
    }

    /* Subscribe */
    .subscriber__wrapper .form-group {
        width: 100%;
    }

    /* breadcrumb */
    .breadcrumb__wrapper {
        padding: 50px 0;
    }
    /* Event Blog Page */
    .ac__icon__back div:last-child div:first-child {
        margin-bottom: 0;
    }
    .blog__events__wrapper{
        padding: 40px 0 10px 0;
    }

    /* event details */
    .events__detils__left .slider__inne .slick-arrow{
        display: none !important;
    }
    .event__paradiv{
        padding: 15px;
    }
    .comment__wrapper .first__name{
        margin-bottom: 15px;
    }
    /* teacher details */
    .teachers__profile{
        display: block;
    }
    .teachers__profile__content {
        margin-left: 0px;
        margin-top: 20px;
    }
}


/*=======================================================================================
                                :-Small Mobile Layout-:-
*=============================================================================================*/
@media only screen and (max-width: 575.98px) {
    h1 {
        font-size: 36px;
        line-height: 45px;
    }

    h2 {
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
    }

    .padding__top__botton {
        padding: 50px 0;
    }
    .margin__top__bottom{
        margin: 50px 0;
    }
    .padding__top {
        padding-top: 50px;
    }

    .padding__bottom {
        padding-bottom: 50px;
    }

    .margin__top {
        margin-top: 50px;
    }

    .margin__bottom {
        margin-bottom: 50px;
    }

    /* Navbar */
    .contact__btn {
        display: none;
    }

    /* Slider */
    .slider__wrapper {
        padding: 45px 0 55px 0;
    }

    .slider__side__color1 {
        top: 37%;
    }

    .slider__inner .slider__para {
        padding: 8px 0 28px 0;
    }

    .slider__wrapper .single__slider {
        padding-left: 0px;
        text-align: center;
    }

    .slider__inner .slick-prev,
    .slider__inner .slick-next {
        display: none !important;
    }

    .single__slider .above__title {
        font-size: 36px;
    }

    /* service */
    .service__inner__content ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
    .more__service a {
        width: 100%;
    }

    /* Why Register */
    .why__img img {
        height: 300px;
    }

    .why__right {
        padding: 56px 28px;
    }

    .why__right .list__one {
        padding-top: 20px;
        display: inline-block;
    }

    .list__one__half img {
        width: 50%;
    }

    .why__right .list__one__half {
        display: inline-flex;
        margin-bottom: 16px;
    }

    .why__right .section__contnet {
        padding-bottom: 10px;
    }

    .why__right .list__one__content {
        padding-left: 0px;
    }

    /* Subscribe */
    .subscriber__wrapper .form-group .btn {
        width: 40%;
    }

    /* Footer */
    .footer__blog__img {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
    }

    .copy__right {
        margin-top: 22px;
    }

    /* Contact */
    .contact__right,
    .contact__left {
        padding: 30px;
    }

    .single__address {
        display: inherit;
        margin-top: 10px;
    }

    .address__content {
        margin-left: 0 !important;
    }

    .page__contact__btn {
        width: 65%;
        height: 40px;
    }

    /* event details */
    .comment__single{
        display: block;
    }
    .comment__content{
        margin-left: 0;
        margin-top: 15px;
    }
    .events__detils__left h3{
        font-size: 28px;
    }
    .events__detils__left h4{
        font-size: 20px;
    }
    .registra__location__wr a {
        margin-bottom: 20px;
    }
    .events__detils__left,
    .event__info_wr,
    .calander__wr{
        padding: 20px;
    }

    /* teacher details */
    .teacher__performance {
        padding: 50px 0 15px 0;
    }
    span.info__list__name{
        padding: 0 6px;
    }
    .th__list__single .th__list__name {
        padding: 0 7px 0 8px;
    }

    /* class details */
    .teacher__category{
        display: block;
    }

    .teacher__category__single{
        margin-bottom: 20px;
    }
    .course__details{
        padding: 25px 0;
    }


}

/*=======================================================================================
                                :- Small Mobile Layout-:-
*=============================================================================================*/
@media only screen and (max-width: 320px) {
    .padding__top__botton {
        padding: 30px 0;
    }
    .margin__top__bottom{
        margin: 30px 0;
    }

    .padding__top {
        padding-top: 30px;
    }

    .padding__bottom {
        padding-bottom: 30px;
    }

    .margin__top {
        margin-top: 30px;
    }

    .margin__bottom {
        margin-bottom: 30px;
    }

    /* Slider */
    .slider__inner .learn__btn {
        margin-left: 0px;
        margin-top: 15px;
    }


}



/*============================================================================
                        :-End Responsive Css-:-
*==================================================================================*/